<template>
  <div>
    <h2 class="mb-2">Events</h2>
    <v-data-table
    :headers="eventHeaders"
    :items="eventsByDate"
    :items-per-page="50"
    item-key="id"
    class="elevation-1"
    sort-desc
    sort-by="createdAt"
    hide-default-footer
    id="events"
    >
        <template v-if="eventsByDate.length" v-slot:body="{items}">
            <tbody>
                <template v-for="(item,i) in items">
                    <tr :key="item.id">
                      <td>{{ lastUpdated(item.createdAt) }}</td>
                      <!-- <td>{{ lastUpdated(item.resolved) }}</td> -->
                      <td>{{ capitalize(item.alarm.type) }}</td>
                      <!-- <td>{{ item.status }}</td> -->
                      <!-- <td>{{ item.alarm.value ? `${item.alarm.type === "low" ? `<` : item.alarm.value : "NA" }}</td> -->
                      <td>{{ item.result === "Resolved" ? "Acknowledged" : item.result || "In Progress" }}</td>
                      <td>{{ resolved(item.createdAt, item.resolved) || "Not Resolved" }}</td>
                      <!-- <td>{{ item.notifications.items.length }}</td> -->
                      <!-- <td>{{ checkNotes(item.notes) }}</td> -->
                      <!-- <td v-if="item.notes"><v-icon @click="updateNotes(item)" color="#009006">mdi-text-box-check-outline</v-icon></td>
                      <td v-else><v-icon @click="updateNotes(item)" color="red">mdi-text-box-edit-outline</v-icon></td> -->
                      <td v-html="checkMessages(item.messages.items)"></td>
                      <td v-if="item.notes" style="cursor: pointer;" @click="updateNotes(item)">{{ item.notes }}</td>
                      <td v-else><v-icon @click="updateNotes(item)" color="red">mdi-text-box-edit-outline</v-icon></td>
                    </tr>
                </template>
            </tbody>
        </template>
        <template v-else v-slot:no-data><span>No Events Found For Time Range</span></template>
    </v-data-table>    
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "events",
  data() {
    return {
      eventHeaders: [
        { text: "Start", value: "createdAt"},
        // { text: "End", value: "resolved" },
        { text: "Type", value: "alarm.type" },
        // { text: "Status", value: "status" },
        // { text: "Trigger", value: "alarm.value" },
        { text: "Result", value: "result" },
        { text: "Resolution Time", value: "" },
        // { text: "Notifications", value: "" },
        { text: "User Response", value: "" },
        { text: "Notes", value: "notes" },
      ]
    };
  },
  components: {},
  async created() {
  },
  computed: {
    ...mapState("sensor", ["sensor", "sensorEvents"]),
    eventsByDate() {
      if (!this.sensor.length) return []
      else return this.sensorEvents.filter(item => item.createdAt >= this.sensor[0].createdAt)
    }
  },
  methods: {
    checkMessages(messages) {
      let msgs = messages.filter(msg => msg.userResponse)
      if (msgs.length) return  msgs.map(msg => `${msg.responseBy}: ${msg.userResponse} (${this.lastUpdated(msg.updatedAt)})`).join('<br>')
      else return "NA "
    },
    lastUpdated(date) {
      let fullDate = new Date(date).toLocaleDateString();
      let todayDate = new Date().toLocaleDateString();
      if (fullDate === todayDate) {
        return `${new Date(date).toLocaleTimeString()}`
      }
      else return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    },
    updateNotes(item) {
      this.$swal({
        input: "textarea",
        // inputLabel: "Event Notes",
        inputPlaceholder: "Enter notes here...",
        inputAttributes: {
          "aria-label": "Enter notes here"
        },
        inputValue: item.notes ? item.notes : "",
        title: 'Event Notes',
        // icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#4baf4f',
        cancelButtonColor: '#d33',
        showLoaderOnConfirm: true,
        confirmButtonText: 'Update',
        'cancelButtonText': 'Cancel',
        'iconColor': this.siteColor,
        preConfirm: async (notes) => {
          item.notes = notes
          await this.$store.dispatch("sensor/updateEventNotes", item)
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$swal('Event notes saved!', '', 'success')
        }
      });
    },
    lastUpdated(date) {
      if (!date) return "In Progress";
      let fullDate = new Date(date).toLocaleDateString();
      let todayDate = new Date().toLocaleDateString();
      if (fullDate === todayDate) {
        fullDate = "Today";
      }
      return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    },
    resolved(initial, final) {
      if (!final) return "Not Resolved";
      let time = ((new Date(final) - new Date(initial)) / 60000)
      if (time > 1440) return `${(time / 1440).toFixed(0)} Day/s`
      else if (time > 60) return `${(time / 60).toFixed(0)} Hour/s`
      else if (time < 1) return `${(time * 60).toFixed(0)} Sec/s`
      else return `${time.toFixed(0)} Min/s`
    }
  },
};
</script>

<style lang="scss">

</style>